<template>
  <q-layout>
    <div class="page-header">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Relatório de Criativos</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Relatório de Criativos" exact />
      </q-breadcrumbs>
    </div>

    <q-page padding>
      <header-creative-reports></header-creative-reports>
      <list-creative-reports></list-creative-reports>
    </q-page>
  </q-layout>
</template>

<script>
import HeaderCreativeReports from '../../components/reports/HeaderCreativeReports.vue';
import ListCreativeReports from '../../components/reports/ListCreativeReports.vue';
import ReportMixin from '../../mixins/ReportMixin';

export default {
  name: 'CreativeReports',
  components: { HeaderCreativeReports, ListCreativeReports },
  mixins: [ReportMixin]
};
</script>
