<template>
  <div>
    <q-toolbar class="q-pr-none q-mb-md justify-end">
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> {{ $t('filter_report') }} </q-tooltip></q-btn
      >
      <q-btn
        outline
        color="primary"
        icon-right="archive"
        class="q-mr-sm"
        no-caps
        @click="exportCreativeReport"
        unelevated
      >
        <q-tooltip> {{ $t('export_csv') }} </q-tooltip>
      </q-btn>
    </q-toolbar>
    <q-intersection transition="slide-down" v-show="filter">
      <q-card flat v-if="filter" bordered class="q-mb-sm">
        <q-toolbar>
          <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
          <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
        </q-toolbar>
        <q-card-section class="q-pt-none row q-col-gutter-md">
          <div class="col-6">
            <select-request
              :v-model="params.campaignId"
              outlined
              ref="campaign"
              endpoint="/redirect?app=ADVERTISER&path=/api/get/campaign"
              option-label="name"
              option-value="id"
              :label="$t('campaign')"
              clearable
              @update="(value) => (params.campaignId = value === null ? null : value.id)"
            />
          </div>
          <div class="col-6">
            <date-input
              buttons
              outlined
              label="Data dos criativos"
              ref="date"
              :v-model="params.date"
              @update="(value) => (params.date = value)"
              :rules="[(val) => val !== '' || 'O campo data dos criativos é obrigatório']"
              clearable
              range
            />
          </div>
          <div class="col-12 text-right">
            <q-btn color="primary" :label="$t('clear')" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
            <q-btn color="positive" :label="$t('search')" no-caps unelevated class="q-px-md" @click="onFilterReports" />
          </div>
        </q-card-section>
      </q-card>
    </q-intersection>
  </div>
</template>

<script>
import ReportMixin from '../../mixins/ReportMixin';
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';

export default {
  name: 'HeaderConversionReports',
  components: { SelectRequest, DateInput },
  mixins: [ReportMixin],
  data() {
    return {
      filter: true,
      params: {
        campaignId: null,
        date: ''
      }
    };
  },

  methods: {
    async exportCreativeReport() {
      const data = await this.getAllCreativeReport();

      this.exportTableCsv(data, this.columns, 'reports_creatives');
    },

    clearFilter() {
      this.params = {
        campaignId: null,
        date: ''
      };
      this.$refs.campaign.value = null;
      this.$refs.date.value = null;
      this.ActionSetFilterReports();
    },

    onFilterReports() {
      if (this.params.date == '') {
        this.errorNotify('É necessário escolher uma data!');
        return;
      }
      let filterSelect = [];

      if (this.params.campaignId) filterSelect.push(`id_campaign=${this.params.campaignId}`);

      if (this.params.date) {
        if (this.params.date.split(' ').length == 3) {
          let [from, , to] = this.params.date.split(' ');
          (from = this.$moment(this.stringToDate(from, 'dd/MM/yyyy', '/')).format('YYYY-MM-DD')),
            (to = this.$moment(this.stringToDate(to, 'dd/MM/yyyy', '/')).format('YYYY-MM-DD'));

          filterSelect.push(`start_date=${from}`);
          filterSelect.push(`end_date=${to}`);
        }
      }

      this.ActionSetFilterReports(filterSelect.join('%26'));
      this.getCreativeReport();
    }
  }
};
</script>
