<template>
  <q-table
    flat
    bordered
    :data="reports"
    row-key="creative_id"
    :columns="columns"
    :pagination="pagination"
    :visible-columns="visibleColumns"
  >
    <template v-slot:top-right>
      <q-select
        v-model="visibleColumns"
        multiple
        outlined
        dense
        options-dense
        :display-value="$t('customize_columns')"
        emit-value
        map-options
        :options="columns"
        option-value="name"
        options-cover
        style="min-width: 150px"
      >
        <template v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }">
          <q-item v-bind="itemProps" v-on="itemEvents">
            <q-item-section>
              <q-item-label v-html="opt.label"></q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-toggle :value="selected" @input="toggleOption(opt)" />
            </q-item-section>
          </q-item>
        </template>
      </q-select>
    </template>
    <template v-slot:bottom>
      <!-- <pagination-table
        @change="getCreativeReport"
        :data="reqPagination"
        :pagination="pagination"
      /> -->
      <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
        Total:{{ reqPagination.total }}
        <q-space />
        <q-pagination
          unelevated
          v-model="currentPage"
          color="primary"
          :max="reqPagination.last_page"
          :max-pages="6"
          boundary-numbers
          direction-links
        />
      </q-toolbar>
    </template>
  </q-table>
</template>

<script>
import ReportMixin from '../../mixins/ReportMixin';
//import PaginationTable from "../../widgets/PaginationTable.vue";
export default {
  //components: { PaginationTable },
  name: 'ListCreativeReports',
  mixins: [ReportMixin],

  data() {
    return {
      visibleColumns: null,

      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      }
    };
  },

  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getCreativeReport(`%26page=${this.currentPage}`, `%26per_page=${this.pagination.rowsPerPage}`);
      }
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },

  created() {
    this.ActionSetFilterReports();
    this.ActionSetReports();
    this.visibleColumns = this.columns.map((column) => column.name);
  }
};
</script>
